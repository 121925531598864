<template>
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.support_documents.title") }}
      </h1>
    </div>

    <div
      class="mt-4"
      v-html="
        validate_support_documents
          ? this.business.business_status_id != BUSINESS_STATUS.APPROVED
            ? $t('business.support_documents.description_no_documents')
            : $t('business.support_documents.description_documents_approved')
          : $t('business.support_documents.description', {
              url: $router.resolve({ name: 'payment-settings' }).href,
            })
      "
    ></div>
  </div>
  <div v-if="!disabled">
    <div class="max-w-3xl" v-if="isHasBusinessSupportDocuments">
      <div
        class="space-y-5"
        v-show="showSelectPaymentMethod && !validate_support_documents"
      >
        <p class="mr-1">
          {{ $t("business.support_documents.preferred_payment_method") }}
        </p>
        <!-- Hide Only FPX options -->
        <!--<app-form-payment-method
          :filterPaymentMethods="[
            PAYMENT_METHODS.ONLINE_BANKING_B2C,
          ]"
          v-model="select_payment_method"
          :multiple="true"
        /> -->

        <!-- <app-form-payment-method :filterPaymentMethods="[
          PAYMENT_METHODS.ONLINE_BANKING_B2C,
          PAYMENT_METHODS.CREDIT_CARD,
        ]" v-model="select_payment_method" :multiple="true" /> -->

        <app-button :disabled="!rule" @click="showSelectPaymentMethod = false">
          {{ $t("general.continue") }}
        </app-button>
      </div>
      <form
        class="space-y-8"
        @submit.prevent="submitSupportDocument"
        v-show="!showSelectPaymentMethod || validate_support_documents"
      >
        <!-- <div
          class="
            p-3
            flex
            rounded
            text-sm
            space-x-3
            items-center
            justify-between
            border border-gray-300
          "
          v-if="!validate_support_documents"
        >
          <div class="flex">
            <p class="mr-1">
              {{ $t("business.support_documents.preferred_payment_method") }}
            </p>
            <div
              v-for="(payment_method, index) in select_payment_method"
              :key="payment_method"
              class="font-semibold"
            >
              <span>{{ index > 0 ? ", " : "" }}</span>
              <span>{{ payment_method?.name }}</span>
            </div>
          </div>
          <p
            class="text-primary cursor-pointer"
            @click="showSelectPaymentMethod = true"
          >
            {{ $t("general.change") }}
          </p>
        </div> -->

        <!-- FPX Documents -->
        <div class="mt-10 space-y-6">
          <p class="text-lg font-semibold">
            {{
              $t("business.support_documents.payment_method_application_fpx")
            }}
          </p>
          <app-upload-support-document
            class="mb-4"
            v-for="(documents, name) in fpx_supporting_documents"
            :max="max"
            :key="name"
            :title="name"
            :errors="errors"
            @refresh="refresh"
            @changed="changed"
            :disabled="disabled || mediaLoading"
            :documents="documents"
            @delete="deleteDocument"
            :type="type_support_documents"
            :rule="validate_support_documents ? 'all' : rule"
          />
        </div>

        <!-- Credit Card Documents -->

        <div class="mt-10 space-y-6">
          <div class="flex container items-center justify-between">
            <p class="text-lg font-semibold">
              {{
                $t("business.support_documents.payment_method_application_cc")
              }}
            </p>
            <button
              type="button"
              @click="showCreditCardOption = !showCreditCardOption"
            >
              <app-icon class="w-6" :name="showCreditCardIcon()" />
            </button>
          </div>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-if="showCreditCardOption">
              <app-upload-support-document
                class="mb-4"
                v-for="(documents, name) in cc_supporting_documents"
                :max="max"
                :key="name"
                :title="name"
                :errors="errors"
                @refresh="refresh"
                @changed="changed"
                :disabled="disabled || mediaLoading"
                :documents="documents"
                @delete="deleteDocument"
                :type="type_support_documents"
                :rule="validate_support_documents ? 'all' : rule"
              />
            </div>
          </transition>
        </div>

        <p
          v-html="
            $t('business.support_documents.terms_and_conditions', {
              url: 'https://drive.google.com/file/d/1jrOl4mB6Qf3v3jrqBDBVoSaX-7ynUC3R/view?usp=share_link',
            })
          "
        />

        <app-button
          type="submit"
          class="mt-10"
          :showf70Icon="true"
          :disabled="!unsaved && disabled"
          :loading="loading || mediaLoading"
        >
          {{ $t("general.continue") }}
        </app-button>
      </form>
    </div>
  </div>
  <div v-else>
    <div class="w-full space-y-5">
      <div v-for="(documents, name) in support_documents" :key="name">
        <p v-if="!$lodash.isEmpty(documents)" class="uppercase text-xs pb-3">
          {{ name?.split("_").join(" ") }}
        </p>
        <div
          v-for="(document, index) in documents"
          :key="index"
          class="
            py-2
            px-4
            flex flex-col
            sm:flex-row sm:space-x-3
            space-x-0
            sm:space-y-0
            space-y-3
            sm:items-center
            items-start
            justify-between
            hover:bg-gray-100
            border-b border-gray-200
          "
          :class="{ 'border-t': index == 0 }"
        >
          <div class="flex-1 flex items-left items-center space-x-5">
            <app-icon-jpg
              v-if="
                document.mime_type.includes('jpeg') ||
                document.mime_type.includes('jpg')
              "
            />
            <app-icon-png v-else-if="document.mime_type.includes('png')" />
            <app-icon-pdf v-else-if="document.mime_type.includes('pdf')" />
            <p class="text-sm">
              {{ document.file_name }}
            </p>
          </div>

          <div class="flex space-x-5">
            <p class="text-xs text-gray-500 mt-1">
              {{ Math.round(document.size / 1024) }} KB
            </p>
            <a
              :href="document.original_url"
              class="flex space-x-1 items-center cursor-pointer text-black"
              target="_blank"
            >
              <p class="text-sm">{{ $t("general.view") }}</p>
            </a>
            <a
              class="flex space-x-1 items-center cursor-pointer text-black"
              @click="download(document)"
            >
              <p class="text-sm">{{ $t("general.download") }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BUSINESS_STATUS from "@/utils/const/business_status";
import PAYMENT_METHODS from "@/utils/const/payment_methods";
import SUPPORT_DOCUMENT from "@/utils/const/support_document";
export default {
  data() {
    return {
      max: 5,
      files: {},
      unsaved: false,
      select_payment_method: [
        {
          id: PAYMENT_METHODS.ONLINE_BANKING_B2C,
        },
        {
          id: PAYMENT_METHODS.CREDIT_CARD,
        },
      ],
      showCreditCardOption: false,
      showSelectPaymentMethod: false,
      PAYMENT_METHODS: PAYMENT_METHODS,
      BUSINESS_STATUS: BUSINESS_STATUS,
      fpx_supporting_documents: {},
      cc_supporting_documents: {},
    };
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    mediaLoading() {
      return this.$store.getters["mediaStore/loading"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    support_documents() {
      return this.$store.getters["businessStore/support_documents"];
    },
    type_support_documents() {
      return this.$store.getters["businessStore/type_support_documents"];
    },
    validate_support_documents() {
      return this.$store.getters["businessStore/validate_support_documents"];
    },
    isHasBusinessSupportDocuments() {
      return !this.$lodash.isEmpty(this.support_documents);
    },
    rule() {
      return !this.$lodash.isEmpty(this.select_payment_method)
        ? this.select_payment_method
            .map((payment_method) => {
              return payment_method.id;
            })
            .includes(PAYMENT_METHODS.CREDIT_CARD)
          ? "all"
          : "required"
        : null;
    },
    business_id() {
      return this.$route.query.business_id;
    },
    disabled() {
      return !(
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  methods: {
    async submitSupportDocument() {
      this.resetState();

      const result = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessSupportDocument",
        {
          documents: this.files,
        }
      );

      if (result.data != null) {
        this.refresh();
        this.$router.push({
          path: "/business/summary",
        });
      } else if (result.message) {
        this.$notify(
          {
            group: "error",
            title: result.message,
          },
          5000
        );
      }
    },
    async deleteDocument(document) {
      const confirm_delete = confirm(
        this.$t("business.support_documents.delete_confirm")
      );

      if (confirm_delete) {
        const result = await this.$store.dispatch(
          "businessStore/deleteBusinessSupportDocument",
          { document_id: document._id }
        );
      }

      this.refresh();
    },
    changed(file, title) {
      this.unsaved = true;
      Object.assign(this.files, { [title]: file });
    },
    download(media) {
      axios
        .get(media.original_url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: media.mime_type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = media.file_name;
          link.click();
        })
        .catch((error) => {
          // if has been blocked by CORS redirect to original url
          window.location.href = media.original_url;
        });
    },
    resetState() {
      this.unsaved = false;
      this.$store.commit("businessStore/setErrorMessage");
    },
    refresh() {
      this.$store.dispatch(
        "businessStore/getBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "businessStore/validateBusinessSupportDocuments",
        this.business_id
      );
      this.$store.dispatch(
        "businessStore/typeBusinessSupportDocuments",
        this.business_id
      );
    },
    showCreditCardIcon() {
      return this.showCreditCardOption ? "ChevronUpIcon" : "ChevronDownIcon";
    },
  },

  watch: {
    support_documents: {
      handler(new_supporting_documents) {
        if (new_supporting_documents.length == 0) return;

        var start_push_cc = false;
        var found_cc_method = false;
        this.fpx_supporting_documents = {};
        this.cc_supporting_documents = {};

        for (const support_document in new_supporting_documents) {
          if (!found_cc_method) {
            if (
              support_document == SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_24 ||
              support_document == SUPPORT_DOCUMENT.DOCUMENT_SSM_FORM_A ||
              support_document == SUPPORT_DOCUMENT.DOCUMENT_SSM_LLP_CERT
            ) {
              found_cc_method = true;
            }

            if (found_cc_method) start_push_cc = found_cc_method;
          }

          if (!start_push_cc) {
            this.fpx_supporting_documents[support_document] =
              new_supporting_documents[support_document];
            continue;
          }

          this.cc_supporting_documents[support_document] =
            new_supporting_documents[support_document];
        }
      },
      immediate: true,
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.unsaved) {
      const confirm = window.confirm(
        this.$t("general.unsaved_changes_warning")
      );
      confirm ? next() : next(false);
    } else {
      next();
    }
  },
};
</script>
